<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REQUEST CHAPEL FUND</h4> </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-row>
              <v-col md="3" cols="12">
                <v-text-field
                  v-model="date_of_request"
                  label="Date of Request"
                  dense
                  outlined
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="credited_amount"
                  label="Credited Amount"
                  dense
                  outlined
                  type="number"
                  min="0"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="credited_to"
                  label="Credited To"
                  dense
                  outlined
                  :rules="rules.default_max_75_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="voucher_no"
                  label="Voucher #"
                  dense
                  outlined
                  type="number"
                  min="0"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <!-- alert -->
              <v-col cols="12" v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>

              <v-col cols="12">
                <v-btn color="primary" class="me-3 mt-4" @click="save_request" v-if="!saving"> Save changes </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate v-else></v-progress-circular>
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-uppercase">ID</th>
                <th class="text-center text-uppercase">Date Requested</th>
                <th class="text-center text-uppercase">Credited To</th>
                <th class="text-center text-uppercase">Voucher #</th>
                <th class="text-center text-uppercase">Amount</th>
                <th class="text-center text-uppercase">Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in chapel_fund_items" :key="item.id">
                <td>{{ item.id }}</td>
                <td class="text-center">
                  {{ item.date_credited }}
                </td>
                <td class="text-center">
                  {{ item.credited_to }}
                </td>
                <td class="text-center">
                  {{ item.voucher_no }}
                </td>
                <td class="text-center">
                  {{ formatPrice(item.amount) }}
                </td>
                <td class="text-center">
                  {{ formatPrice(item.balance) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color" :snackbar_text="this.snackbar_text" />
  </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiDelete } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    date_of_request: '',
    credited_amount: 0,
    credited_to: '',
    voucher_no: 0,

    saving: false,
    alert: false,
    alert_message: '',

    chapel_fund_items: [],
  }
}
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiDelete,
      },
    }
  },
  created() {
    this.initialize_data()
  },
  data() {
    return initialState()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'month_of', 'user_id']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('chapel_fund', ['get_chapel_fund', 'save_chapel_fund']),
    reset() {
      Object.assign(this.$data, initialState())
      this.initialize_data()
    },
    initialize_data() {
      const data = new FormData()
      data.append('branch_id', this.branch_id)
      data.append('month_of', this.month_of)
      this.get_chapel_fund(data)
        .then(response => {
          this.chapel_fund_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    save_request() {
      this.saving = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('date_credited', moment(this.date_of_request, 'YYYY-MM-DD').format('MMMM D, YYYY'))
        data.append('month_of', this.month_of)
        data.append('credited_to', this.credited_to.toUpperCase())
        data.append('amount', this.credited_amount)
        data.append('voucher_no', this.voucher_no)
        this.save_chapel_fund(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.reset()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving = false
      }
    },
  },
}
</script>
